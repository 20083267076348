.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.top {
  flex: 0;
  border-bottom: solid 1px var(--adm-color-border);
}

.body {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom {
  flex: 0;
  border-top: solid 1px var(--adm-color-border);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #fbff00;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.container {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
}

.ant-form-item-required {
  color: white !important;
  /*you can change the color */
}

label {
  color: white !important;
  /*you can change the color */
}

.adm-card {
  padding: 0px;
}

/* Global styles */


video {
  max-width: 100%;
  height: auto;
}