.app {
    height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: 'prompt';
    font-size: 15px;
    background-color: rgb(0, 0, 0);
}

.top {
    flex: 0;
    border-bottom: solid 1px var(--adm-color-border);
    background: '#007bff';
    color: white; /* Set the font color to white */
}

.body {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50;
    background-color: rgb(0, 0, 0);
    min-height: 100vh;
    /* margin-top: 50px;
    margin-bottom: 50px; */
    /* max-height:  '100%'; */
}

.bottom {
    flex: 0;
    border-top: solid 1px var(--adm-color-border);
    color: '#ffffff';
}

.custom-tab-item {
    color: white; /* Set the font color to white */
  }